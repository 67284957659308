.Content {
  min-height: 70vh;
  margin: 1em 0 1em 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #1d1d1d;
    margin: 0 0 1.5em 0;
  }

  p {
    text-align: justify;
    margin: 0 0 1em 0;
  }

  ul {
    padding-left: 2em;
  }
}

.AboutBackground {
  margin-bottom: 2em;

  li {
    margin: 0.2em;
  }

  .AboutBackgroundContent {
    margin: 0 2em 0 2em;

    ul {
      padding-left: 0;
    }

    li {
      list-style-type: none;
    }
  }
}

.AboutTech {
  .AboutTechList {
    column-width: 300px;

    margin: 0 2em 0 2em;

    color: #1d1d1d;

    .AboutTechListSection {
      margin: 0em 0 1em 0;

      column-span: none;
      break-inside: avoid-column;

      h4 {
        margin: 0 0 0.5em 0;
      }

      ul {
        padding-left: 1.5em;
      }
    }
  }
}
