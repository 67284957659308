* {
  margin: 0;
  padding: 0;
}

html {
  background: radial-gradient(
    circle,
    rgb(243, 243, 243) 85%,
    rgb(241, 241, 241) 100%
  );

  min-height: 100vh;
  background-size: cover;
}

body {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  color: #1d1d1d;
}

.App {
  margin: 5vh auto;

  display: flex;
  flex-direction: column;

  width: 80vw;

  @import "_header.scss";
  @import "_content.scss";
  @import "_footer.scss";
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.emphasis {
  color: rgb(158, 0, 0);
}

a {
  color: inherit;
}
