.Header {
  color: #1d1d1d;

  display: flex;
  flex-direction: row;
  height: 100px;

  .Logo {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;

    color: rgb(158, 0, 0);

    text-decoration: none;

    .LogoLink {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .Nav {
    width: 75%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .NavLinks {
      display: flex;
      flex-direction: row;

      list-style-type: none;

      float: right;

      .NavLink {
        width: 110px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        cursor: pointer;

        text-align: center;

        font-size: 1.5em;

        &:hover {
          background-color: rgb(184, 184, 184);
        }
      }
    }
  }
}
